<!--
 * @Description:合同列表
 * @Author: zby
 * @Date: 2021-11-23 16:57:59
 * @LastEditors: zby
 * @Reference:
-->
<template>
  <div class="tree-menu__box" :style="'width:' + stretchWidth">
    <div v-show="isTreeStretch && !isStretchExpand" class="expand">
      <i class="el-icon-arrow-right" @click="() => (isStretchExpand = true)"></i>
    </div>
    <div v-show="isTreeStretch && isStretchExpand" class="shrinkage">
      <i class="el-icon-arrow-left" @click="() => (isStretchExpand = false)"></i>
    </div>
    <div v-show="isShowDetail" class="tit">
      <div class="tit_name">{{ this.treeMenuName }}</div>
      <div v-if="this.arrSum" class="arrSum">{{ projectArr.length + '条' }}</div>
      <!--       <div v-if="operationShowAddBtn && operationExport" class="export" @click="exportShow">
        <el-tooltip class="item" effect="dark" content="点击导出项目" placement="top-end">
          <GLIcon icon="icon-xiangmudaochu" :extraStyle="true" class="exportIcon" downIcon="icon-xiangmudaochu-jizhong" />
        </el-tooltip>
      </div> -->
      <el-select v-if="operationShowStatusSelect" class="dataStatus" size="mini" v-model="statusEq" placeholder="请选择" @change="refreshTree">
        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <div v-if="operationShowAddBtn && operationCreate" @click="dialogFormVisible = true" class="add_btn">
        <el-tooltip class="item" effect="dark" placement="top-start">
          <div slot="content">{{ $t('projectManagement.clickCreateNewProject') }}</div>
          <GLIcon icon="icon-xiangmudaoru" class="addIcon" :extraStyle="true" downIcon="icon-xiangmudaoru-jizhong" />
        </el-tooltip>
      </div>
      <div v-else-if="createPermission" @click="affairDialogFormVisible = true" class="add_btn">
        <el-tooltip class="item" effect="dark" placement="top-start">
          <div slot="content">{{ $t('publicManagement.clickCreateNewAffair') }}</div>
          <GLIcon icon="icon-xiangmudaoru" class="addIcon" :extraStyle="true" downIcon="icon-xiangmudaoru-jizhong" />
        </el-tooltip>
      </div>
    </div>
    <div v-show="isShowDetail" class="search">
      <el-input
        v-if="searchName && !searchType"
        v-model="searchText"
        @input="searchInput"
        :placeholder="searchName"
        prefix-icon="el-icon-search"
        @clear="clearInput"
        clearable
      >
      </el-input>
      <el-date-picker
        v-else-if="searchName && searchType"
        @change="searchInput"
        v-model="dateVal"
        value-format="yyyy-MM-dd"
        type="daterange"
        class="projInput"
        range-separator="-"
      ></el-date-picker>
    </div>

    <div class="ul_box" v-show="isShowDetail">
      <transition-group v-loading="boxLoading" v-if="searchData.length" tag="ul" name="ul-slide" class="ulList">
        <li
          v-for="(i, idx) in searchData"
          :key="i.id || i.name"
          :class="[{ choosed: mutipleActive ? chooseJudge(idx) : idx === chooseIdx }, { downChoosed: clickCheck === idx }]"
          @mousedown="switchColor(1, idx)"
          @mouseup="switchColor(0, idx)"
          @click="chooseContract(idx, i)"
        >
          <!-- <GLIcon
            v-if="routeTit === '运营管理'"
            :iconClosed="i.status ? 'icon_closed' : ''"
            :icon="iconDynamicType(i.status, idx)"
            iconClass="icon_hetong"
          ></GLIcon> -->

          <GLIcon :icon="iconDynamicType(i.status, idx)" iconClass="icon_hetong" :iconClosed="i.status ? 'icon_closed' : ''"></GLIcon>
          <el-tooltip effect="dark" v-if="i.name.length >= 20" :content="i.name" placement="top-end">
            <span class="folder_name">{{ i.name }}</span>
          </el-tooltip>
          <span class="folder_name" v-else>{{ i.name }}</span>
          <span v-if="i.isRecently" class="frequently">常用</span>
        </li>
      </transition-group>
      <div v-else class="empty_tip">暂无数据</div>
    </div>
    <ProjExport :dialogExportVisible="dialogExportVisible" @closeDiago="closeDiago" :treeData="treeData" :exportTree="exportTree" />
    <SimpleDialog
      class="add_project"
      title="创建新项目"
      :visible.sync="dialogFormVisible"
      width="580px"
      showConfirm
      showCancel
      @onCancel="onNoticeDialogClose"
      @onSubmit="onNoticeDialogSubmit"
    >
      <el-form class="dialogRuleForm" :model="dialogRuleForm" ref="dialogRuleForm" label-position="right" :rules="rules" label-width="80px">
        <el-form-item prop="code" label="项目编号">
          <el-input v-model="dialogRuleForm.code" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="projectName" label="项目名称">
          <el-input v-model="dialogRuleForm.projectName" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="belong" label="项目归属">
          <el-select v-model="dialogRuleForm.belong" filterable clearable>
            <el-option v-for="item in belongs" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="type" label="项目类型">
          <el-select v-model="dialogRuleForm.type" filterable clearable>
            <el-option v-for="item in typeList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="file" label="批量创建">
          <el-upload
            :file-list="dialogRuleForm.file"
            :auto-upload="false"
            :limit="1"
            action="string"
            :on-remove="handleRemove"
            :on-change="handleChange"
          >
            <el-button slot="trigger" icon="el-icon-upload">上传文件</el-button>
            <div slot="tip" class="el-upload__tip" style="margin-top: 0; color: #c0c4cc; height: 24px; line-height: 24px; position: relative">
              <span style="position: absolute; left: 0">支持批量上传，支持扩展名：.xls，.xlsx</span>
              <el-button type="text" icon="el-icon-download" class="downloadBTN" @click="downloadImportTemplate">点击下载文件模板</el-button>
            </div>
          </el-upload>
        </el-form-item>
        <el-alert
          type="warning"
          title="导入文件列名必须包含模板文件要求的内容，不允许列名称修改、重复或者调换顺序！"
          :closable="false"
          show-icon
          center
          style="margin-top: 0px"
        />
      </el-form>
    </SimpleDialog>
    <SimpleDialog
      class="add_project"
      title="创建新事项"
      :visible="affairDialogFormVisible"
      width="580px"
      showConfirm
      showCancel
      @onCancel="affairDialogFormVisible = false"
      @onSubmit="onNoticeDialogSubmit_sec"
    >
      <el-form
        class="affairDialogRuleForm"
        label-width="100px"
        label-position="right"
        :model="affairDialogRuleForm"
        ref="affairDialogRuleForm"
        :rules="affairRules"
      >
        <el-form-item prop="affairName" label="事项名称">
          <el-input v-model="affairDialogRuleForm.affairName" clearable @change="validateReset" placeholder="请输入事项名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人">
          <el-select v-model="affairDialogRuleForm.directorSelected" filterable clearable>
            <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="第二负责人">
          <el-select v-model="affairDialogRuleForm.secondDirectorSelected" filterable clearable>
            <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="面向人员" class="personnelOriented-class">
          <el-radio-group v-model="affairDialogRuleForm.showRange" class="persion-group">
            <el-radio-button v-for="i in showRangeOptions" :label="i.value" :key="i.value">{{ i.label }}</el-radio-button>
          </el-radio-group>
          <memberSelect
            :allUser="allUser"
            :projectUpdate="true"
            :memberSelected="affairDialogRuleForm.staffs"
            @changeMemberSelected="changeMemberSelected"
            v-show="affairDialogRuleForm.showRange === 1"
          />
        </el-form-item>
      </el-form>
    </SimpleDialog>
    <!-- 创建成功 -->
    <el-dialog :visible="createSuccessVisible" :show-close="false">
      <template slot="title">
        <div class="dialogHeader">
          <i class="el-icon-success icon" style="color: #58ba73"></i>
          <span class="text">已创建</span>
        </div>
      </template>
      <div slot="footer">
        <el-button @click="closeSuccessDialog" type="primary">确定</el-button>
      </div>
      <el-table :data="successResult" border :header-cell-style="{ backgroundColor: '#f8f8f9' }">
        <el-table-column prop="projectName" label="项目名称"></el-table-column>
        <el-table-column prop="group" label="执行部门"></el-table-column>
        <el-table-column prop="servingCount" label="服务项数量"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 创建失败 -->
    <el-dialog :visible="createFailedVisible" :show-close="false">
      <template slot="title">
        <div class="dialogHeader">
          <i class="el-icon-error icon" style="color: #da4f2a"></i>
          <span class="text">创建失败</span>
        </div>
      </template>
      <div slot="footer">
        <el-button @click="cancelCreate">取消</el-button>
        <el-button @click="resetCreate" type="primary">重新创建</el-button>
      </div>
      <el-table :data="failedResult" border :header-cell-style="{ backgroundColor: '#f8f8f9' }">
        <el-table-column prop="index" label="出错行"></el-table-column>
        <el-table-column prop="desc" label="出错原因"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import ProjExport from '../common/ProjExport';
import memberSelect from '@/components/memberSelect/index.vue';
import _ from 'lodash';

import projectManagementApi from '@/api/modules/projectManagement.api.js';
import publicManagementApi from '@/api/modules/publicAffairsManagement.api.js';

export default {
  name: 'TreeMenu',
  components: { ProjExport, memberSelect },
  props: {
    choosedContractItem: {
      type: Object,
      default: () => {
        return null;
      },
    },
    boxLoading: {
      type: Boolean,
      default: false,
    },
    projectArr: {
      type: Array,
      default: () => {
        return [];
      },
    },

    changeItems: {
      type: Array,
      default: () => {
        return [];
      },
    },

    // 确认单页面多tag表单是否激活
    mutipleActive: {
      type: Boolean,
      default: false,
    },
    // 单页面多tag表单激活时的关键参数
    /*
    1.当mutipleCheck等于映射处于激活状态的tag表单时，通过chooseJudge来动态地在对应表单生成与激活状态匹配的class
    */
    mutipleCheck: {
      type: Boolean,
      default: false,
    },
    operationShowStatusSelect: {
      type: Boolean,
      default: false,
    },
    isTreeStretch: {
      type: Boolean,
      default: false,
    },
    arrSum: {
      type: Boolean,
      default: false,
    },
    operationShowAddBtn: {
      type: Boolean,
      default: false,
    },
    remoteSearch: {
      type: Boolean,
      default: false,
    },
    operationCreate: {
      type: Boolean,
      default: false,
    },
    searchType: {
      type: String,
      default: '',
    },
    initialState: {
      type: Boolean,
      default: false,
    },
    operationExport: {
      type: Boolean,
      default: false,
    },
    leaveWarnCheck: {
      type: Number,
      default: 0,
    },
    projectStatus: {
      type: Number,
      default: 0,
    },
    createPermission: {
      type: Boolean,
      default: false,
    },
    warnKeyWord: {
      type: String,
      default: '数据',
    },

    treeMenuName: {
      type: String,
      default: '项目',
    },
    allUser: {
      type: Array,
      default() {
        return [];
      },
    },
    routeTit: {
      type: String,
      default: '',
    },
    searchName: {
      type: String,
      default: '查找项目',
    },
  },
  computed: {
    iconDynamicType() {
      // icon_closed
      return (status, idx) => {
        if (this.chooseJudge(idx) && !status) {
          return 'icon-xiangmujizhong-active';
        } else if (!this.chooseJudge(idx) && !status) {
          return 'icon-xiangmujizhong';
        } else if (status) {
          return this.chooseJudge(idx) ? 'icon-jieshu-jizhong' : 'icon-jieshu1';
        }
      };
    },

    stretchWidth() {
      return this.isStretchExpand ? '260px' : '30px';
    },
    isShowDetail() {
      return (this.isTreeStretch && this.isStretchExpand) || !this.isTreeStretch;
    },
    chooseJudge() {
      return idx => {
        // 如果是多选的情况
        if (this.mutipleCheck) {
          return idx === this.chooseIdx;
        } else {
          return false;
        }
      };
    },
  },
  watch: {
    projectArr: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        if (!this.remoteSearch) {
          this.filterProjectArr();
        } else if (!_.isEqual(newVal, oldVal)) {
          this.filterProjectArr();
        }
      },
    },
    projectStatus: {
      deep: true,
      immediate: false,
      handler(val) {
        this.statusEq = val;
      },
    },
  },
  data() {
    const validAffairName = (rule, value, callback) => {
      if (this.AffairNameError) {
        callback(new Error('该事项名称已被占用'));
      } else if (!value) {
        callback(new Error('请填写事项名称'));
      } else {
        callback();
      }
    };
    return {
      dateVal: [],

      statusEq: 0,
      statusOptions: [
        { label: '已结束', value: 1 },
        { label: '进行中', value: 0 },
        { label: '全部', value: null },
      ],
      isStretchExpand: true,
      showRangeOptions: [
        { label: '部分员工', value: 1 },
        { label: '全体员工', value: 0 },
      ],
      onlyTip: false,
      lastChooseIdx: 0,
      projectType: ['DM', 'STAT', 'MED', 'PK', 'CP', '研发', '其他'],
      AffairNameError: false,
      clickCheck: -1,
      hetongIcon: 'icon-hetong',
      treeData: [
        {
          id: 1,
          label: '全量',
          value: 'detail',
          children: [
            {
              id: 2,
              label: '项目维度',
              value: 'project',
            },
            {
              id: 3,
              label: '服务项维度',
              value: 'serving',
            },
            {
              id: 4,
              label: '日工作量详情',
              value: 'daily',
            },
          ],
        },
      ],

      listType: '',
      firstCreated: true,

      exportTree: ['detail', 'project', 'serving', 'daily'],
      searchText: '',
      searchData: [],
      // 默认选择第一个
      chooseIdx: 0,
      // 导出项目弹窗
      dialogExportVisible: false,
      //添加项目弹窗
      dialogFormVisible: false,
      // allUser: [], //返回的所有供选择的人
      dialogRuleForm: {
        projectName: '',
        code: '',
        affairName: '',
        secondDirectorSelected: '', //第二负责人
        belong: '', // 项目归属
        type: '', // 项目类型
        file: [], // 文件列表
      },
      rules: {
        code: [{ required: true, message: '请输入项目编号', trigger: 'blur' }],
        projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        affairName: [{ required: true, message: '请输入事项名称', trigger: 'blur' }],
        secondDirectorSelected: [{ required: true, message: '请选择第二负责人', trigger: 'change' }],
        belong: [{ required: true, message: '请选择项目归属', trigger: 'change' }],
        // type: [{ required: true, message: '请选择项目类型', trigger: 'change' }],
        // file: [{ required: true, message: '请上传文件', trigger: 'change' }],
      },

      //添加事项弹窗
      affairDialogFormVisible: false,
      affairDialogRuleForm: {
        showRange: null,
        affairName: '',
        directorSelected: '', //负责人
        secondDirectorSelected: '', //第二负责人
        staffs: [],
      },
      affairRules: {
        affairName: [{ required: true, validator: validAffairName, trigger: 'blur' }],
      },
      belongs: ['国内', '国外'],
      typeList: [],
      createSuccessVisible: false,
      createFailedVisible: false,
      successResult: [],
      failedResult: [],
    };
  },

  methods: {
    // 获取项目类型
    async getTypeList() {
      let [typeData] = await projectManagementApi.getProjectTypeList();
      if (typeData) this.typeList = typeData.types;
    },
    searchInput: _.debounce(function (val) {
      if (val === '') return; // 如果是清空文本操作，另外处理
      this.filterProjectArr(val);
    }, 800),
    clearInput() {
      this.$emit('searchKeyword', this.searchText);
    },
    validateReset() {
      this.AffairNameError = false;
    },
    changeMemberSelected(vals, ids) {
      this.affairDialogRuleForm.staffs = [...ids];
    },
    switchColor(bool, idx) {
      if (bool) {
        this.chooseIdx = idx;
        this.clickCheck = idx;
      } else {
        this.clickCheck = -1;
      }
    },
    refreshTree(val) {
      this.$emit('transfromStatus', val);
    },
    chooseContract(idx, i, bool) {
      if (idx === -1) return;
      if (this.leaveWarnCheck && !bool) {
        this.$confirm('你有已编辑的' + this.warnKeyWord + '尚未保存，确定要离开吗？提交后后未保存的内容将丢失', '注意', {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
        })
          .then(async () => {
            if (i['showRange'] === 0) {
              // listType用于双列表标记具体选择了哪个列表
              this.listType = true;
            }
            console.log(idx);

            this.lastChooseIdx = this.chooseIdx;
            this.chooseIdx = idx;
            this.$emit('getChoosedContractItem', this.searchData[idx], this.listType, bool);
            this.listType = false;
          })
          .catch(() => {
            this.chooseIdx = this.lastChooseIdx;
          });
      } else {
        if (i['showRange'] === 0) {
          // listType用于双列表标记具体选择了哪个列表
          this.listType = true;
        }
        this.lastChooseIdx = this.chooseIdx;
        this.chooseIdx = idx;
        this.$emit('getChoosedContractItem', this.searchData[idx], this.listType, bool);
        this.listType = false;
      }
    },
    filterProjectArr(val = '') {
      let searchVal = val;
      if (this.remoteSearch) {
        // 在这里区分一下日期搜索器和输入搜索器
        if (this.searchType) {
          this.$emit('searchAuditDate', this.dateVal, this.searchType);
        } else {
          if (val !== '') {
            this.$emit('searchKeyword', this.searchText);
          }
        }
      } else {
        if (!searchVal) {
          searchVal = this.searchText ? this.searchText : '';
        }
      }
      if (this.searchType) {
        this.searchData = [...this.projectArr];
      } else {
        this.searchData = this.projectArr.filter(i => {
          return i.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1;
        });
      }

      // 刷新列表以后，如果选中的项存在(这里非进入首页时首次刷新的状态)，则获取其index值，然后追踪和定位到相关的值的位置
      if (this.choosedContractItem && this.choosedContractItem.id) {
        const chooseIdx = this.searchData.findIndex(item => {
          return item.id === this.choosedContractItem.id;
        });
        this.chooseContract(chooseIdx, this.choosedContractItem, true);
      }
    },
    onNoticeDialogClose() {
      this.dialogRuleForm = {
        projectName: '',
        code: '',
        affairName: '',
        secondDirectorSelected: '', //第二负责人
        belong: '', // 项目归属
        type: '', // 项目类型
        file: [], // 文件列表
      };
      this.$refs['dialogRuleForm'].resetFields();
      this.dialogFormVisible = false;
    },
    //创建新项目
    async onNoticeDialogSubmit() {
      this.$refs['dialogRuleForm'].validate(async valid => {
        if (!valid) {
          return this.$message.warning('请输入合法数据');
        }
        const formData = new FormData();
        formData.append('name', this.dialogRuleForm.projectName);
        formData.append('code', this.dialogRuleForm.code);
        formData.append('belong', this.dialogRuleForm.belong);
        formData.append('type', this.dialogRuleForm.type ? this.dialogRuleForm.type : null);
        formData.append('file', this.dialogRuleForm.file[0] ? this.dialogRuleForm.file[0] : null);
        const res = await projectManagementApi.importProject(formData);
        if (res[0].errors) {
          this.$message.error('新建项目失败！');
          this.failedResult = res[1].data.errors;
          this.createFailedVisible = true;
        } else {
          this.$message.success('新建项目成功！');
          this.successResult = res[1].data.importProjectResult;
          this.createSuccessVisible = true;
        }
      });
    },
    closeSuccessDialog() {
      this.createSuccessVisible = false;
      this.onNoticeDialogClose();
      this.$emit('addProject');
    },
    cancelCreate() {
      this.createFailedVisible = false;
      this.onNoticeDialogClose();
    },
    resetCreate() {
      this.createFailedVisible = false;
    },
    // 打开导出窗口
    exportShow() {
      this.dialogExportVisible = true;
    },
    // 关闭导出窗口
    closeDiago(val) {
      this.dialogExportVisible = val;
    },
    //创建新事项
    onNoticeDialogSubmit_sec() {
      const q = {
        affair: {
          name: this.affairDialogRuleForm.affairName,
          showRange: this.affairDialogRuleForm.showRange,
        },
        primOwner: this.affairDialogRuleForm.directorSelected ? [{ id: this.affairDialogRuleForm.directorSelected }] : [],
        secOwner: this.affairDialogRuleForm.secondDirectorSelected ? [{ id: this.affairDialogRuleForm.secondDirectorSelected }] : [],
        staffs: this.affairDialogRuleForm.staffs,
      };
      this.$refs['affairDialogRuleForm'].validate(async valid => {
        if (!valid) {
          return;
        }

        let data = await publicManagementApi.saveAffair(q);
        if (!data[1].code && data[2].status !== 404) {
          this.$emit('addProject');
          this.$message.success('新建事项成功！');
          this.$refs['affairDialogRuleForm'].resetFields();
          this.affairDialogFormVisible = false;
        } else if (data[1].metadata) {
          const errData = data[1].metadata;
          errData.name ? (this.AffairNameError = true) : null;
          this.$refs.affairDialogRuleForm.validate();
        }
      });
    },
    handleChange(file) {
      this.dialogRuleForm.file.push(file.raw);
    },
    handleRemove() {
      // 置空文件列表（当前设置 limit 为 1）
      this.dialogRuleForm.file = [];
    },
    async downloadImportTemplate() {
      try {
        const BaseServiceApi = process.env.VUE_APP_SERVICE_BASE_URL;
        window.location.href = `${BaseServiceApi}api/pmf/v1/projects/importTemplate`;
        this.$message.success('下载成功');
      } catch (error) {
        this.$message.error('下载失败');
      }
    },
  },
  created() {
    this.getTypeList();
  },
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
}
.tree-menu__box {
  border-radius: 2px;
  position: relative;
  width: 260px;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  .expand,
  .shrinkage {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    border-right: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: #f8f8f8f9;
  }

  .tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: auto;
    padding: 18px 16px 20px;
    // height: 70px;

    .tit_name {
      flex: auto !important;
      min-width: 40px;
      font-size: 15px;
      padding-left: 2px;
      font-weight: 600;
      color: #17233d;
    }
    .dataStatus {
      padding-right: 5px;
      ::v-deep .el-input__inner {
        height: 28px;
        width: 94px;
      }
    }
    .arrSum {
      font-size: 14px;
      color: #2d8cf0;
    }
    .add_btn,
    .export {
      width: 28px;
      height: 28px;
      background-color: #fff;
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      i {
        color: #515a6e;
        font-weight: 700;
        // position: relative;
      }
      &:hover i {
        color: #2d8cf0;
        // &:hover span {
        //   display: block;
        //   position: absolute;
        //   top: -22px;
        //   left: 2px;
        //   width: 100px;
        //   border: 1px solid blue;
        //   background-color: red;
        //   text-align: center;
        // }
      }
    }
    .export {
      margin-left: 110px;
      .exportIcon {
        width: 28px;
        height: 28px;
      }
    }
    .add_btn {
      .addIcon {
        width: 28px;
        height: 28px;
      }
    }
  }

  .search {
    padding: 0 16px 10px;
    .projInput {
      background: #fff !important;
      width: 230px !important;
      border: 1px solid #dcdee2 !important;
      ::v-deep .el-range-input {
        font-size: 12px;
      }
      ::v-deep .el-input__icon {
        line-height: 32px !important;
        height: 32px;
      }
    }
    // .el-input__inner {
    //   color: #515a6e;
    //   background-color: #f8f8f9;
    //   height: 32px;
    //   border: none;
    //   line-height: 32px;
    // }
    ::v-deep .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
    ::v-deep .el-input__icon {
      line-height: 28px;
    }
    .el-icon-search {
      display: flex;
      align-items: center;
      padding-left: 8px;
    }
  }

  .ul_box {
    overflow-y: scroll;
    height: calc(100% - 70px - 42px - 10px);
    padding: 0 16px;
    /*     .ul-slide-enter-active,
    .ul-slide-leave-active {
      transition: all 0.3s ease;
    }
    .ul-slide-enter,
    .ul-slide-leave-to {
      transform: translateX(10px);
      opacity: 0;
    } */
    .ul-slide-move {
      transition: transform 0.5s;
    }
    .ulList {
      li {
        position: relative;
        display: flex;
        align-items: center;
        vertical-align: middle;
        font-size: 14px;
        cursor: pointer;
        padding: 6px 0;
        border-radius: 4px;
        width: 100%;
        .icon_hetong {
          width: 22px;
          height: 22px;
          color: #808695;
          flex-shrink: 0;
          use {
            width: 100%;
            height: 100%;
          }
        }
        &:hover {
          background: #f8f8f9;
          background-size: 100%;
          border-radius: 4px;
        }
        .icon_closed {
          width: 12px;
          height: 12px;
          line-height: 12px;
          margin-left: 6px;
        }
        .folder_name {
          color: #515a6e;
          padding-left: 5px;
          width: 180px;
          line-height: 20.5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .frequently {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          margin-right: 8px;
          text-align: center;
          width: 36px;
          height: 18px;
          border-radius: 2px;
          background: #19be6b;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
        }
      }
      .choosed {
        background: #fff;
        .folder_name {
          color: #2d8cf0;
        }
      }
      .downChoosed {
        background: #f2f9fe !important;
        .folder_name {
          color: #2d8cf0;
        }
      }
    }

    .empty_tip {
      text-align: center;
      margin-top: 20px;
      color: #515a6e;
      font-size: 14px;
    }
  }

  .add_project {
    .el-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 24px 32px;
      margin-bottom: 0;

      .el-form-item {
        margin-bottom: 32px;
        ::v-deep .el-form-item__label {
          color: #515a6e;
          font-weight: normal;
          font-size: 14px;
        }
      }
      .personnelOriented-class {
        margin-right: 0;
        min-width: 400px;
        .persion-group {
          min-width: 120px;
          padding-right: 12px;
          ::v-deep .el-radio-button__inner {
            height: 32px;
            padding: 0 8px;

            line-height: 30px;
          }
        }
        ::v-deep .el-form-item__content {
          display: flex;
          flex: auto;
          .memberSelectTags {
            width: 214px !important;
          }
        }
      }
    }
    .affairDialogRuleForm {
      .el-input,
      .el-select {
        width: 368px;
      }
    }
    .dialogRuleForm {
      .el-input,
      .el-select {
        width: 100%;
      }

      ::v-deep .el-form-item {
        margin-bottom: 18px;
      }
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  .tit {
    display: flex;
    justify-content: space-around !important;
    align-items: center;
    flex: auto;
    padding: 8px 10px !important;
    // height: 70px;
    .tit_name {
      display: flex;
      font-size: 15px;
      padding-left: 2px;
      font-weight: 600;
      color: #17233d;
    }

    .arrSum {
      font-size: 14px;
      color: #2d8cf0;
    }
    .add_btn,
    .export {
      width: 28px;
      height: 28px;
      background-color: #fff;
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      i {
        color: #515a6e;
        font-weight: 700;
        // position: relative;
      }
      &:hover i {
        color: #2d8cf0;
        // &:hover span {
        //   display: block;
        //   position: absolute;
        //   top: -22px;
        //   left: 2px;
        //   width: 100px;
        //   border: 1px solid blue;
        //   background-color: red;
        //   text-align: center;
        // }
      }
    }
    .export {
      margin-left: 40px !important;
      .exportIcon {
        width: 28px;
        height: 28px;
      }
    }
    .add_btn {
      .addIcon {
        width: 28px;
        height: 28px;
      }
    }
  }
}
::v-deep .el-range-editor .el-range-separator {
  line-height: 24px !important;
}
::v-deep .el-upload--text {
  width: 100%;

  .el-button {
    width: 100%;
    height: 48px;
    background-color: #f5f5f7;
  }
}
.downloadBTN {
  height: 100%;
  line-height: 100%;
  padding: 0 !important;
  position: absolute;
  right: 0;
}

.dialogHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  .icon {
    font-size: 24px;
  }

  .text {
    font-size: 18px;
    font-weight: 600;
    margin-left: 6px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1550px) {
  .tree-menu__box {
    .search {
      .projInput {
        width: 200px !important;
      }
    }
  }
}

::v-deep .el-dialog__body {
  padding: 0 20px;
}
</style>
