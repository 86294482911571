import { debounce } from '@/utils/debounce';

export const mixinsWorkRecord = {
  data() {
    return {
      calcFilterHeight: 0,
      calcTreeHeight: 0,
      otherDomHeight: 0,
    };
  },
  computed: {
    treeHeight() {
      return {
        height: this.calcTreeHeight,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTreeDom();
      this.calTableDom();
      this.calTableRangeWidth();
    });
    window.addEventListener('resize', this.calcTreeDom);
    window.addEventListener('resize', this.calTableDom);
    window.addEventListener('resize', this.calTableRangeWidth);
  },
  activated() {
    this.$nextTick(() => {
      this.calcTreeDom();
      this.calTableRangeWidth();
    });
    window.addEventListener('resize', this.calcTreeDom);
    window.addEventListener('resize', this.calTableDom);
    window.addEventListener('resize', this.calTableRangeWidth);
  },
  deactivated() {
    window.removeEventListener('resize', this.calcTreeDom);
    window.removeEventListener('resize', this.calTableDom);
    window.removeEventListener('resize', this.calTableRangeWidth);
  },
  methods: {
    calcTreeDom() {
      const resizeDom = debounce(() => {
        this.calcTreeHeight = this.chooseTree ? (window.innerHeight - 120) / 2 + 'px' : window.innerHeight - 60 - 40 + 'px';
        this.calcFilterHeight = window.innerHeight - 60 - (this.chooseTree ? 16 : 0) + 'px';
      }, 0);
      resizeDom();
    },
  },
};
