import { BaseService } from '@/api/request';
const api = {};

/**
 * @description: 检索公共事务
 * @param  {*name 检索关键词}
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 */
api.getPublicAffairs = function () {
  return BaseService.getRequest('api/pmf/v1/affairs');
};

/**
 * @description: 获取公共事务（整个页面）详情
 * @param  {*affair 公共事务id}
 */
api.getAffairDetail = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/affair/getAffairDetail', params);
};

/**
 * @description: 保存公共事务事项
 */
api.saveAffair = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/affair/saveAffair', params);
};

/**
 * @description: 保存公共事务的任务
 */
api.saveAffairTask = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/affair/saveAffairTask', params);
};

export default api;
