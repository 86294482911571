<template>
  <div id="selectTagComps">
    <el-select
      class="memberSelectTags"
      filterable
      v-model.lazy="selectVals"
      @change="changeMemberChoosed"
      multiple
      :disabled="disabled"
      :placeholder="specifiedPlaceholder"
    >
      <el-option v-for="item in allUser" :key="item.id" :label="item[optionLabel]" :value="item.id"> </el-option>
    </el-select>

    <!-- blur需要focus先进行激活，然后才能被激活使用 -->
    <el-tag @close="handleCloseTag(0)" :class="['first-name', { 'is-disabled': disabledUpdate }, { clearTags: !members.length }]" closable>
      {{ members[0] }}
    </el-tag>
    <div class="tipShadow" v-if="selectVals.length > 1" tabindex="0" @focus="SelectShowTips" @blur="SelectShowTips">
      <el-tag :class="['other-name', { tipBackground: memberOptShow }]">
        {{ '+' + filterMembers.length }}
      </el-tag>
    </div>

    <div class="multiple" v-show="memberOptShow" tabindex="1" @focus="SelectShowTips" @blur="SelectShowTips('memberClosed')">
      <el-tag
        v-for="(i, index) in filterMembers"
        @close="handleCloseTag(++index)"
        :key="index"
        :class="['simpleTag', { 'is-disabled': disabledUpdate }]"
        closable
      >
        {{ i }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberSelect',

  props: {
    disabledUpdate: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    projectUpdate: {
      type: Boolean,
    },
    memberSelected: {
      type: Array,
      default() {
        return [];
      },
    },
    allUser: {
      type: Array,
      default() {
        return [];
      },
    },
    specifiedPlaceholder: {
      type: String,
      default: '请选择',
    },
    optionLabel: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      memberOptShow: false,
      members: [],
      filterMembers: [],
      selectVals: [],
      // memberOptions: [],
    };
  },
  watch: {
    /*     allUser: {
      deep: true,
      immediate: false,
      handler(val) {
        this.memberOptions = [...val];
      },
    }, */
    memberSelected: {
      deep: true,
      immediate: true,
      handler(val) {
        this.selectVals = [...val];

        this.members = val.map(sval => {
          return this.allUser.filter(i => {
            return sval == i.id;
          })?.[0]?.[this.optionLabel];
        });
        this.filterMembers = this.members.slice(1, this.members.length);
      },
    },
  },
  methods: {
    SelectShowTips(event) {
      if (event === 'memberClosed') {
        this.memberOptShow = false;
        return;
      }
      const sourceZone = event.target?._prevClass;
      const tagsZone = event.relatedTarget?._prevClass;
      if (event.type === 'focus') {
        this.memberOptShow = true;
        return;
      }
      if (event.type === 'blur' && sourceZone === 'tipShadow' && tagsZone !== 'multiple') {
        this.memberOptShow = false;
      }
    },

    handleCloseTag(idx) {
      if (!this.projectUpdate) {
        return idx;
      }
      /* label和value是同时进行删减的，但是因为传值是分开的，故要进行分开操作 */
      this.members.splice(idx, 1);
      this.selectVals.splice(idx, 1);
      this.$emit('changeMemberSelected', this.members, this.selectVals);
    },
    changeMemberChoosed(selectVals) {
      this.members = selectVals.map(sval => {
        return this.allUser.filter(i => {
          return sval == i.id;
        })[0][this.optionLabel];
      });

      this.selectVals = selectVals.map(sval => {
        return this.allUser.filter(i => {
          return sval == i.id;
        })[0].id;
      });
      this.$emit('changeMemberSelected', this.members, this.selectVals);
    },
  },
};
</script>

<style lang="scss" scoped>
#selectTagComps {
  line-height: 32px !important;
  position: relative;
  line-height: 32px;
  .slot-down {
    position: absolute;
    right: 0;
    bottom: 22px;
    height: 12.5px;
  }

  .memberSelectTags {
    width: 100%;
    border-radius: 4px;

    ::v-deep .el-select__tags {
      // margin-left: 120px;
      .el-tag {
        display: none;
      }
      .el-select__input {
        padding-left: 120px;
      }
    }
    ::v-deep .el-input {
      height: 32px !important;
      width: 100% !important;
      line-height: 32px !important;
    }
    ::v-deep .el-input__inner {
      cursor: text;
      height: 32px !important;
      line-height: 32px !important;
    }
  }
  .first-name,
  .other-name {
    z-index: 5;
    min-width: 40px;
    padding: 0;
    text-align: center;
    ::v-deep .el-icon-close {
      color: #515a6e;
      left: 0;
    }
    ::v-deep .el-icon-close:hover {
      color: #fff;
    }
  }

  .first-name {
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 2px;
    min-width: 72px;
    border: 1px solid #dcdee2;
  }
  .clearTags {
    display: none !important;
  }
  .tipShadow {
    display: inline-block;
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 80px;
    .other-name:hover {
      color: #2d8cf0;
      border: 1px dashed #2d8cf0;
    }
  }

  .tipBackground {
    color: #2d8cf0;
    border: 1px dashed #2d8cf0;
    background: #f2f9fe !important;
  }
  ::v-deep .el-tag--light {
    line-height: 24px;
    height: 24px;
    margin: 0 5px;
    text-align: center;
    cursor: pointer;
    color: #515a6e;
    background: #f8f8f9;
    border-radius: 4px;
    border: 1px dashed #dcdee2;
  }

  .is-disabled {
    background-color: #c5c8ce;
    border-color: #dcdee2;
    color: #515a6e;
  }
  .multiple {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 12px;
    z-index: 99999999999;
    position: absolute;
    width: 352px;
    background: #ffffff;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    margin: 4px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);

    .el-tag {
      color: #2d8cf0;
      max-width: 60px;
      padding: 0;
      height: 28px;
      padding-left: 5px;
      background: #f2f9fe !important;
      border-radius: 4px;
      border: 1px solid rgba(45, 140, 240, 0.3);
      margin: 3px 2px;

      ::v-deep .el-icon-close {
        left: 0;
      }
    }
    .simpleTag {
      padding-top: 2px;
      padding-right: 2px;
      max-width: 110px;
    }
  }

  .multiple::before {
    content: '';
    top: -6px;
    left: 100px;
    position: absolute;
    border-left: 8px solid transparent;
    border-bottom: 6px solid #e4e7ed;
    border-right: 8px solid transparent;
  }

  .multiple::after {
    content: '';
    top: -5px;
    left: 100px;
    position: absolute;
    border-left: 8px solid transparent;
    border-bottom: 6px solid #fff;
    border-right: 8px solid transparent;
  }
}
</style>
