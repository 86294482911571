import { render, staticRenderFns } from "./TreeMenu.vue?vue&type=template&id=3208aa27&scoped=true"
import script from "./TreeMenu.vue?vue&type=script&lang=js"
export * from "./TreeMenu.vue?vue&type=script&lang=js"
import style0 from "./TreeMenu.vue?vue&type=style&index=0&id=3208aa27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3208aa27",
  null
  
)

export default component.exports